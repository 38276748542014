module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.3,"once":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"medienkram","short_name":"medienkram","start_url":"/","background_color":"#0e3b6b","theme_color":"#0e3b6b","display":"minimal-ui","icon":"src/assets/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ead170d3869a6778abd06573c778214f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#0e3b6b","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/stefanhelker/workspaces/intern/portfolio2020/src/locales","languages":["en","de"],"defaultLanguage":"de","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":":"},"pages":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
